import '@/assets/style/global.less';
import { useBrand, useRuntimeInfo } from '@/hooks';
import useNormalizeStylePosition from '@/hooks/useNormalizeStylePosition';
import store from '@/store';
import { setReferrerURL } from '@/utils/session';
import { lazyWithPreload } from '@zenlayer/portal-utils';
import '@zenlayer/zen-design-pro/dist/style.css';
import { Suspense } from 'react';
import { Provider } from 'react-redux';

const Entry = lazyWithPreload(() => import('@/entry'));
const App = () => {
  useBrand();
  setReferrerURL();
  useNormalizeStylePosition();

  const { ready } = useRuntimeInfo();

  return (
    <Suspense>
      {ready && (
        <Provider store={store}>
          <Entry />
        </Provider>
      )}
    </Suspense>
  );
};

export default App;
